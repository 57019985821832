import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DataService } from './data.service';
import { InLoginRes } from '../models/login.interface';
import { InGlobalRes } from '../models/globalRes.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  'role_ids': number[] = [1, 2, 3, 4, 5, 6, 7, 8]; // user role ids allowed

  constructor(
    private http: HttpClient,
    private dataService: DataService
  ) {}

  login(email: string, password: string) {
    return this.http
      .post<InLoginRes>(`${environment.apiUrl}/users/login`, {
        email,
        password
      })
      .pipe(
        map((res) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes

          // Roles registered (spanish names - english names)
          // 1- Supervisor (web access restricted) - access added on 01-19-2022
          // 2- Auditor (web access restricted) - access added on 01-19-2022
          // 3- Administrador de supervisores - Super admin *
          // 4- Administrador de auditores - Auditor admin (no incidences) *
          // 5- Jefe de departamento - Department manager (department incidences) *
          // 6- Gerente de tienda- Store manager (All reports and incidences) *
          // 7- Director general - General manager (All reports) *
          // 8- Administrador - Admin *************

          if (res.status) {
            // Validate user access to panel
            this.dataService.setUserValue(res.data.user);
            this.dataService.setTokenValue(res.data.accessToken);

            /*
            if (this.role_ids.includes(res.data.user.role.id)) { // Allow access only to role user registered on array role_ids
              this.dataService.setUserValue(res.data.user);
              this.dataService.setTokenValue(res.data.accessToken);
            } else {
              res.status = false;
              res.msg = 'Usuario inválido, no tienes acceso al panel';
            }
            */
          }
          return res;
        })
      );
  }

  logout() {
    return this.http
      .post<InGlobalRes>(`${environment.apiUrl}/users/logout`, {})
      .pipe(
        map((res) => {
         // remove user from local storage to log user out
          this.dataService.clear();
          return res.status;
        })
      );
  }
}
