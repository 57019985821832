export const routes: { path: string }[] = [
    {
        path: '/home'
    },
    {
        path: '/verifications/completed'
    },
    {
        path: '/incidences'
    },
    {
        path: '/reports/auditor/general'
    },
    {
        path: '/reports/supervisor/general'
    },
];
