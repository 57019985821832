import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/core/service/data.service';
import { InFilter } from 'src/app/interfaces/filter.interface';
import { Branch } from 'src/app/models/branch';

@Component({
  selector: 'app-branch-filter',
  templateUrl: './branch-filter.component.html',
  styleUrls: ['./branch-filter.component.sass']
})
export class BranchFilterComponent implements OnInit, OnDestroy {
  branches: Branch[] = [];
  branchesControl = new FormControl(0);
  branchesSubscription: Subscription;

  filter: InFilter;
  filterSubscription: Subscription;

  constructor(
    private dataService: DataService
  ) {
    this.branchesSubscription = this.dataService.branches.subscribe((data) => {
      this.branches = data;
    });

    this.filter = this.dataService.filterValue;
    this.filterSubscription = this.dataService.filter.subscribe((filter) => {
      this.filter = filter;
      this.dataService.filterData();
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.branchesSubscription.unsubscribe();
    this.filterSubscription.unsubscribe();
  }

  branchesSelect(event: any): void {
    this.filter.branch_id = event.value;
    this.dataService.setFilter(this.filter);
  }

}
