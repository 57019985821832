export const routes: {path: string}[] = [
    {
      path: '/home'
    },
    {
      path: '/users'
    },
    {
      path: '/roles'
    },
    {
      path: '/catalogues'
    },
    {
      path: '/branches'
    },
    {
      path: '/departments'
    },
    {
      path: '/questions'
    },
    {
      path: '/surveys/list'
    },
    {
      path: '/surveys/assign'
    },
    {
      path: '/verifications/ended'
    },
    {
      path: '/verifications/completed'
    },
    {
      path: '/verifications/supervisor'
    },
    {
      path: '/incidences'
    },
    {
      path: '/reports/auditor/general'
    },
    {
      path: '/reports/supervisor/general'
    },
    {
      path: '/reports/supervisor/monthly'
    },
    {
      path: '/reports/report/dynamic'
    },
    {
      path: '/suggestions/register'
    },
    {
      path: '/suggestions/reports'
    }
];
