import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/core/service/data.service';
import { InFilter } from 'src/app/interfaces/filter.interface';
import { Department } from 'src/app/models/department';

@Component({
  selector: 'app-department-filter',
  templateUrl: './department-filter.component.html',
  styleUrls: ['./department-filter.component.sass']
})
export class DepartmentFilterComponent implements OnInit, OnDestroy {
  departments: Department[] = [];
  departmentControl = new FormControl(0);
  departmentSubscription: Subscription;

  filter: InFilter;
  filterSubscription: Subscription;

  constructor(
    private dataService: DataService
  ) {
    this.departmentSubscription = this.dataService.departments.subscribe((data) => {
      this.departments = data;
    });

    this.filter = this.dataService.filterValue;
    this.filterSubscription = this.dataService.filter.subscribe((filter) => {
      this.filter = filter;
      this.dataService.filterData();
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.departmentSubscription.unsubscribe();
    this.filterSubscription.unsubscribe();
  }

  departmentSelect(event: any): void {
    this.filter.department_id = event.value;
    this.dataService.setFilter(this.filter);
  }
}
