<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <form class="login100-form">
        <span class="error-header p-b-45">
          404
        </span>
        <span class="error-subheader p-b-5">
          Parece que te has perdido
        </span>
        <span class="error-subheader2 p-b-5">
        La página que buscas no está disponible
        </span>
        <div class="container-login100-form-btn p-t-30	">
          <button mat-flat-button color="primary" class="login100-form-btn" (click)='submit()'>
            Ir a la página de inicio
          </button>
        </div>
        <div class="w-full p-t-15 p-b-15 text-center">
          <div>
            <a href="#" class="txt1">
            </a>
          </div>
        </div>
      </form>
      <div class="login100-more" style="background-image: url('assets/images/esmeralda/404.png');">
      </div>
    </div>
  </div>
</div>
