export const routes: {path: string}[] = [
  {
    path: '/home'
  },
  {
    path: '/catalogues'
  },
  {
    path: '/questions'
  },
  {
    path: '/surveys/list'
  },
  {
    path: '/surveys/assign'
  },
  {
    path: '/verifications'
  },
  {
    path: '/reports/auditor/general'
  }
];
