import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InBranchResA } from 'src/app/interfaces/branch.interface';
import { InDepartmentResA } from 'src/app/interfaces/department.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  API_URL = environment.apiUrl;
  route = '/auth';

  constructor(
    private http: HttpClient
  ) { }

 getBranches(active = null): Observable<InBranchResA> {
    if (active === 0 || active === 1) {
      return this.http.get<InBranchResA>(`${this.API_URL}${this.route}/branches?active=${active}`, {});
    }
    return this.http.get<InBranchResA>(`${this.API_URL}${this.route}/branches`, {});
  }

  getDepartments(active = null): Observable<InDepartmentResA> {
    if (active === 0 || active === 1) {
      return this.http.get<InDepartmentResA>(`${this.API_URL}${this.route}/departments?active=${active}`, {});
    }
    return this.http.get<InDepartmentResA>(`${this.API_URL}${this.route}/departments`, {});
  }

  getBranchesIncidences(): Observable<InBranchResA> {
    return this.http.get<InBranchResA>(`${this.API_URL}${this.route}/branches-incidences`, {});
  }
}
