<mat-form-field class="col-6 header-select" appearance="outline">
    <mat-label>Sucursal</mat-label>
    <mat-select  [formControl]="branchesControl" (selectionChange)="branchSelect($event)">
        <mat-option [value]="0">
           Todos
        </mat-option>
        <mat-option *ngFor="let branch of branches" [value]="branch">
          {{branch.name}}
        </mat-option>
    </mat-select>
</mat-form-field>

<mat-form-field class="col-6 header-select" appearance="outline">
    <mat-label>Departamento</mat-label>
    <mat-select  [formControl]="departmentsControl" (selectionChange)="departmentSelect($event)">
        <mat-option [value]="0">
           Todos
        </mat-option>
        <mat-option *ngFor="let department of departments" [value]="department.id">
          {{department.name}}
        </mat-option>
    </mat-select>
</mat-form-field>