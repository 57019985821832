<mat-form-field class="col header-select" appearance="outline">
    <mat-label>Sucursal</mat-label>
    <mat-select  [formControl]="branchesControl" (selectionChange)="branchesSelect($event)">
        <mat-option [value]="0">
           Todos
        </mat-option>
        <mat-option *ngFor="let branch of branches" [value]="branch.id">
          {{branch.name}}
        </mat-option>
    </mat-select>
</mat-form-field>