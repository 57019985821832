import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { AuthLayoutComponent } from './components/layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './components/layout/app-layout/main-layout/main-layout.component';
import { Page404Component } from './components/authentication/page404/page404.component';
import { AuthUserGuard } from './core/guard/auth-user.guard';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard, AuthUserGuard],
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      {
        path: '',
        loadChildren: () =>
          import('./components/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          )
      },
      {
        path: '',
        loadChildren: () =>
          import('./components/sections/sections.module').then(
            (m) => m.SectionsModule
          )
      },
      {
        path: 'user',
        loadChildren: () =>
          import('./components/user/user.module').then(
            (m) => m.UserModule
          )
      },
    ]
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./components/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      )
  },
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
