import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { ROUTES } from 'src/app/components/layout/sidebar/sidebar-items/sidebar-items';
import { DataService } from '../service/data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthUserGuard implements CanActivate {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userRole = this.dataService.userValue.role_id;
    if (userRole) {
      let letroutes
      if(userRole>8)
        letroutes = ROUTES.find(rs => rs.role_id === 0);
      else
        letroutes = ROUTES.find(rs => rs.role_id === userRole);
      const routes =letroutes
      const found = routes.component_routes.find(r => r.path === state.url);
      if (found) {
        return true;
      }
    }

    this.router.navigate(['/home']);
    return false;
  }
}
