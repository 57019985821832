import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/core/service/data.service';
import { InFilter } from 'src/app/interfaces/filter.interface';
import { InUserTypes } from 'src/app/interfaces/user-types.interface';

@Component({
  selector: 'app-type-filter',
  templateUrl: './type-filter.component.html',
  styleUrls: ['./type-filter.component.sass']
})
export class TypeFilterComponent implements OnInit, OnDestroy {
  userTypes: InUserTypes[] = [];
  userTypeControl = new FormControl(0);

  filter: InFilter;
  filterSubscription: Subscription;

  isAuthorized = true;

  @Output() changeType = new EventEmitter<number>();

  constructor(
    private dataService: DataService,
  ) {
    // Check if this user is able to use all actions ( only admin or store manager can)
    this.isAuthorized = this.dataService.userValue.role_id === 8 || 6 ? true : false;

    this.userTypes = this.dataService.userTypes;

    this.filter = this.dataService.filterValue;
    this.filterSubscription = this.dataService.filter.subscribe((filter) => {
      this.filter = filter;
      this.dataService.filterData();
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.filterSubscription.unsubscribe();
  }

  userTypeSelect(event: any): void {
    this.changeType.emit(event.value);
    this.filter.type = event.value;
    this.dataService.setFilter(this.filter);
  }

}
