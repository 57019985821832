<mat-form-field class="header-select" appearance="outline">
    <mat-label>Fecha</mat-label>
    <mat-date-range-input [rangePicker]="rangePicker">
      <input matStartDate placeholder="F. inicio">
      <input matEndDate placeholder="F. fin">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
    <mat-date-range-picker #rangePicker>
      <mat-date-range-picker-actions>
        <button mat-button matDateRangePickerCancel>Cancelar</button>
        <button mat-raised-button color="primary" matDateRangePickerApply>Aceptar</button>
      </mat-date-range-picker-actions>
    </mat-date-range-picker>
</mat-form-field>