<mat-form-field *ngIf="isAuthorized" class="col header-select" appearance="outline">
    <mat-label>Tipo</mat-label>
    <mat-select  [formControl]="userTypeControl" (selectionChange)="userTypeSelect($event)">
        <mat-option [value]="0">
          Todos
        </mat-option>
        <mat-option *ngFor="let user of userTypes" [value]="user.id">
          {{user.name}}
        </mat-option>
    </mat-select>
</mat-form-field>