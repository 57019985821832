import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/core/service/data.service';
import { InFieldTypes } from 'src/app/interfaces/field.interface';
import { InFilter } from 'src/app/interfaces/filter.interface';

@Component({
  selector: 'app-field-filter',
  templateUrl: './field-filter.component.html',
  styleUrls: ['./field-filter.component.sass']
})
export class FieldFilterComponent implements OnInit {

  fields: InFieldTypes[] = [];
  selected: string[];
  fieldControl = new FormControl(['branch_id', 'department_id', 'incidences']);

  filter: InFilter;
  filterSubscription: Subscription;

  isAuthorized = true;

  @Output() changeField = new EventEmitter<string[]>();

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.isAuthorized = this.dataService.userValue.role_id === 8 || 6 ? true : false;
    this.fields = this.dataService.fieldTypes;
    this.selected = this.fields.filter(field => field.active).map(field => field.name);
  }

  fieldSelect(event: any): void {
    this.changeField.emit(event.value);
  }

}
