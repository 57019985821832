<section class="content">
    <div class="container-fluid">
      <div class="block-header">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="d-flex justify-content-between">
              <ul *ngIf="navigation.title" class="breadcrumb breadcrumb-style ">
                <li class="breadcrumb-item">
                  <h4 class="page-title">{{navigation.title}}</h4>
                </li>
                <li *ngIf="navigation.subtitle" class="breadcrumb-item bcrumb-1">
                  {{navigation.subtitle}}
                </li>
              </ul>

              <div *ngIf="dateFilter.start" class="align-self-center pr-4 font-17">
                <span class="mr-2" style="font-weight: 600;">Rango de fechas: </span>{{dateFilter.start | date:'d MMM y'}}  <span class="" style="font-weight: 600;">al</span> {{dateFilter.end | date:'d MMM y'}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column">
        <!-- section content -->
        <router-outlet></router-outlet>
      </div>
    </div>
</section>
  