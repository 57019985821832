import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/core/service/data.service';
import { InFilter } from 'src/app/interfaces/filter.interface';
import { InStatusTypes } from 'src/app/interfaces/status-types';

@Component({
  selector: 'app-status-filter',
  templateUrl: './status-filter.component.html',
  styleUrls: ['./status-filter.component.sass']
})
export class StatusFilterComponent implements OnInit, OnDestroy {
  // this status filter has three posible values
  // 1 = started
  // 2 = in progress
  // 0 = closed

  statusTypes: InStatusTypes[] = [];
  statusTypesControl = new FormControl(10);

  filter: InFilter;
  filterSubscription: Subscription;

  isAuthorized = true;

  constructor(
    private dataService: DataService,
  ) {
    this.statusTypes = this.dataService.statusTypes;

    this.filter = this.dataService.filterValue;
    this.filterSubscription = this.dataService.filter.subscribe((filter) => {
      this.filter = filter;
      this.dataService.filterData();
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.filterSubscription.unsubscribe();
  }

  statusTypeSelect(event: any): void {
    this.filter.status_type = event.value;
    this.dataService.setFilter(this.filter);
  }

}
