<mat-form-field *ngIf="isAuthorized" class="col header-select" appearance="outline">
    <mat-label>Campo</mat-label>
    <mat-select [formControl]="fieldControl" (selectionChange)="fieldSelect($event)" multiple>
        <mat-option
          *ngFor="let field of fields"
          [value]="field.key"
          [disabled]="((field.key == 'incidences' || field.key == 'minutes') && (fieldControl.value.indexOf('incidences') > -1 && fieldControl.value.indexOf('minutes') > -1))
            || (field.key == 'minutes' && fieldControl.value.indexOf('incidences') > -1)
            || (field.key == 'incidences' && fieldControl.value.indexOf('minutes') > -1)">
          {{field.name}}
        </mat-option>
    </mat-select>
</mat-form-field>