export const routes: { path: string }[] = [
    {
        path: '/home'
    },
    {
        path: '/incidences'
    },
    {
        path: '/reports/auditor/general'
    },
    {
        path: '/reports/supervisor/general'
    },
    {
        path: '/reports/supervisor/monthly'
    }
];
