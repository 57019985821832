import { NgModule } from '@angular/core';
import { GalleriaModule } from 'primeng-lts/galleria';
import { DividerModule } from 'primeng-lts/divider';

const primeModules = [
  GalleriaModule,
  DividerModule
];

@NgModule({
  declarations: [],
  imports: [primeModules],
  exports: [primeModules]
})
export class PrimengModule { }
