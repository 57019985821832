import { Routes } from '@angular/router';
import { routes as adminRoutes } from 'src/app/components/sections/routes/admin';
import { routes as adminAuditorRoutes } from 'src/app/components/sections/routes/auditor-admin';
import { routes as adminSupervisorRoutes } from 'src/app/components/sections/routes/supervisor-admin';
import { routes as securityHygieneRoutes } from 'src/app/components/sections/routes/security-hygiene';
import { routes as proccesesRoutes } from 'src/app/components/sections/routes/processes';
import { routes as supervisorRoutes } from 'src/app/components/sections/routes/supervisor';
import { routes as auditorRoutes } from 'src/app/components/sections/routes/auditor';
import { routes as butcherRoutes } from 'src/app/components/sections/routes/butcher';
import { routes as bManagerRoutes } from 'src/app/components/sections/routes/branch-manager';
import { routes as departmentManagerRoutes } from 'src/app/components/sections/routes/department-manager';
import { routes as managerRoutes } from 'src/app/components/sections/routes/manager';
import { routes as adminSuggestionsRoutes } from 'src/app/components/sections/routes/admin-suggestion';
import { RouteInfo } from '../sidebar.metadata';

export interface InRoutes {
  role_id: number;
  routes: RouteInfo[];
  component_routes: Routes;
  components?: string;
}

const admin: RouteInfo[] = [
  // {
  //   path: 'home',
  //   title: 'Inicio - Resumen',
  //   moduleName: 'home',
  //   icon: 'speed',
  //   class: '',
  //   groupTitle: false,
  //   submenu: []
  // },
  {
    path: 'users',
    title: 'Usuarios',
    moduleName: 'users',
    viewName: 'users',
    icon: 'group',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'roles',
    title: 'Roles',
    moduleName: 'roles',
    viewName: 'roles',
    icon: 'account_balance_wallet',
    class: '',
    groupTitle: false,
    submenu: []
  },

  {
    path: 'roles_views',
    title: 'Roles-Vistas',
    moduleName: 'roles_views',
    viewName: 'roles_views',
    icon: 'account_circle',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'views',
    title: 'Vistas',
    moduleName: 'views',
    viewName: 'views',
    icon: 'aspect_ratio',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'catalogues',
    title: 'Catálogos',
    moduleName: 'catalogues',
    viewName: 'catalogues',
    icon: 'view_module',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'branches',
    title: 'Sucursales',
    moduleName: 'branches',
    viewName: 'branches',
    icon: 'store',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'departments',
    title: 'Departamentos',
    moduleName: 'departments',
    viewName: 'departments',
    icon: 'safety_divider',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'questions',
    title: 'Preguntas',
    moduleName: 'questions',
    viewName: 'questions',
    icon: 'help_center',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '',
    title: 'Plantillas',
    moduleName: 'surveys',
    viewName: '',
    icon: 'list_alt',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'surveys/list',
        title: 'Listado',
        moduleName: 'surveys',
        viewName: 'survery-list',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'surveys/assign',
        title: 'Asignaciones',
        moduleName: 'surveys',
        viewName: 'surveys-assign',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Verificaciones',
    moduleName: 'verifications',
    viewName: 'verifications',
    icon: 'list_alt',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'verifications/ended',
        title: 'En ejecución',
        moduleName: 'verifications',
        icon: '',
        viewName: 'verifications-ended',
        class: 'grading',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'verifications/completed',
        title: 'Terminadas',
        moduleName: 'verifications',
        viewName: 'verifications-completed',
        icon: '',
        class: 'grading',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'verifications/supervisor',
        title: 'Superv/SH/Proc/Carn/Merca/Geren',
        moduleName: 'verifications-supervisor',
        viewName: 'verifications-supervisor',
        icon: '',
        class: 'grading',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: 'incidences',
    title: 'Incidencias',
    moduleName: 'incidences',
    viewName: 'incidences',
    icon: 'rule',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '',
    title: 'Reportes',
    moduleName: 'reports',
    viewName: 'reports',
    icon: 'poll',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: '/',
        title: 'Superv/SH/Proc/Carn/Merca/Geren',
        moduleName: 'supervisor',
        viewName: 'supervisor',
        icon: '',
        class: 'ml-sub-menu',
        groupTitle: false,
        submenu: [
          {
            path: 'reports/supervisor/general',
            title: 'General',
            moduleName: 'supervisor',
            viewName: 'reports-supervisor-general',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'reports/supervisor/monthly',
            title: 'Mensual',
            moduleName: 'supervisor',
            viewName: 'reports-supervisor-monthly',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          }
        ]
      },
      {
        path: '/',
        title: 'Auditor',
        moduleName: 'auditor',
        viewName: 'auditor',
        icon: '',
        class: 'ml-sub-menu',
        groupTitle: false,
        submenu: [
          {
            path: 'reports/auditor/general',
            title: 'General',
            moduleName: 'auditor',
            viewName: 'reports-auditor-general',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          }
        ]
      },
      {
        path: 'reports/report/dynamic',
        title: 'Dinámico',
        moduleName: 'auditor',
        viewName: 'reports-report-dynamic',
        icon: '',
        class: 'ml-sub-sub-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Quejas y sugerencias',
    moduleName: 'suggestions',
    viewName: 'suggestions',
    icon: 'report_problem',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: '/suggestions/register',
        title: 'Registro',
        moduleName: 'suggestions-register',
        viewName: 'suggestions-register',
        icon: '',
        class: 'ml-sub-sub-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/suggestions/reports',
        title: 'Reporte',
        moduleName: 'suggestions-report',
        viewName: 'suggestions-report',
        icon: '',
        class: 'ml-sub-sub-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  }
];

const cm: RouteInfo[] = [
  // {
  //   path: 'home',
  //   title: 'Inicio - Resumen',
  //   moduleName: 'home',
  //   icon: 'speed',
  //   class: '',
  //   groupTitle: false,
  //   submenu: []
  // },
  {
    path: 'users',
    title: 'Usuarios',
    moduleName: 'users',
    viewName: 'users',
    icon: 'group',
    class: '',
    groupTitle: false,
    submenu: []
  },

  {
    path: 'roles_views',
    title: 'Roles-Vistas',
    moduleName: 'roles_views',
    viewName: 'roles_views',
    icon: 'account_circle',
    class: '',
    groupTitle: false,
    submenu: []
  },

  {
    path: 'roles',
    title: 'Roles',
    moduleName: 'roles',
    viewName: 'roles',
    icon: 'account_balance_wallet',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'views',
    title: 'Vistas',
    moduleName: 'views',
    viewName: 'views',
    icon: 'aspect_ratio',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'catalogues',
    title: 'Catálogos',
    moduleName: 'catalogues',
    viewName: 'catalogues',
    icon: 'view_module',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'branches',
    title: 'Sucursales',
    moduleName: 'branches',
    viewName: 'branches',
    icon: 'store',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'departments',
    title: 'Departamentos',
    moduleName: 'departments',
    viewName: 'departments',
    icon: 'safety_divider',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'questions',
    title: 'Preguntas',
    moduleName: 'questions',
    viewName: 'questions',
    icon: 'help_center',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '',
    title: 'Plantillas',
    moduleName: 'surveys',
    viewName: 'surveys',
    icon: 'list_alt',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'surveys/list',
        title: 'Listado',
        moduleName: 'surveys',
        viewName: 'surveys-list',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'surveys/assign',
        title: 'Asignaciones',
        moduleName: 'surveys',
        viewName: 'surveys-assign',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Verificaciones',
    moduleName: 'verifications',
    viewName: 'verifications',
    icon: 'list_alt',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'verifications/ended',
        title: 'En ejecución',
        moduleName: 'verifications',
        icon: '',
        viewName: 'verifications-ended',
        class: 'grading',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'verifications/completed',
        title: 'Terminadas',
        moduleName: 'verifications',
        viewName: 'verifications-completed',
        icon: '',
        class: 'grading',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'verifications/supervisor',
        title: 'Superv/SH/Proc/Carn/Merca/Geren',
        moduleName: 'verifications-supervisor',
        viewName: 'verifications-supervisor',
        icon: '',
        class: 'grading',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: 'incidences',
    title: 'Incidencias',
    moduleName: 'incidences',
    viewName: 'incidences',
    icon: 'rule',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '',
    title: 'Reportes',
    moduleName: 'reports',
    viewName: 'reports',
    icon: 'poll',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: '/',
        title: 'Superv/SH/Proc/Carn/Merca/Geren',
        moduleName: 'supervisor',
        viewName: 'supervisor',
        icon: '',
        class: 'ml-sub-menu',
        groupTitle: false,
        submenu: [
          {
            path: 'reports/supervisor/general',
            title: 'General',
            moduleName: 'supervisor',
            viewName: 'reports-supervisor-general',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'reports/supervisor/monthly',
            title: 'Mensual',
            moduleName: 'supervisor',
            viewName: 'reports-supervisor-monthly',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          }
        ]
      },
      {
        path: '/',
        title: 'Auditor',
        moduleName: 'auditor',
        viewName: 'auditor',
        icon: '',
        class: 'ml-sub-menu',
        groupTitle: false,
        submenu: [
          {
            path: 'reports/auditor/general',
            title: 'General',
            moduleName: 'auditor',
            viewName: 'reports-auditor-general',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          }
        ]
      },
      {
        path: 'reports/report/dynamic',
        title: 'Dinámico',
        moduleName: 'auditor',
        viewName: 'reports-report-dynamic',
        icon: '',
        class: 'ml-sub-sub-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  }
];

const auditorAdmin: RouteInfo[] = [
  {
    path: 'catalogues',
    title: 'Catálogos',
    moduleName: 'catalogues',
    viewName: 'catalogues',
    icon: 'view_module',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'questions',
    title: 'Preguntas',
    moduleName: 'questions',
    viewName: 'questions',
    icon: 'help_center',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '',
    title: 'Plantillas',
    moduleName: 'surveys',
    viewName: 'surveys',
    icon: 'list_alt',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'surveys/list',
        title: 'Listado',
        moduleName: 'surveys',
        viewName: 'surveys-list',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'surveys/assign',
        title: 'Asignaciones',
        moduleName: 'surveys',
        viewName: 'surveys-list',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Verificaciones',
    moduleName: 'verifications',
    viewName: 'verifications-list',
    icon: 'list_alt',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'verifications/ended',
        title: 'En ejecución',
        moduleName: 'verifications',
        viewName: 'verifications-ended',
        icon: '',
        class: 'grading',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'verifications/completed',
        title: 'Terminadas',
        moduleName: 'verifications',
        viewName: 'verifications-completed',
        icon: '',
        class: 'grading',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Reportes',
    moduleName: 'reports',
    viewName: 'reports',
    icon: 'poll',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'reports/auditor/general',
        title: 'General',
        moduleName: 'reports',
        viewName: 'reports-auditor-general',
        icon: '',
        class: 'grading',
        groupTitle: false,
        submenu: []
      }
    ]
  }
];

const supervisorAdmin: RouteInfo[] = [
  {
    path: 'questions',
    title: 'Preguntas',
    moduleName: 'questions',
    viewName: 'question',
    icon: 'help_center',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '',
    title: 'Plantillas',
    moduleName: 'surveys',
    viewName: 'surveys',
    icon: 'list_alt',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'surveys/list',
        title: 'Listado',
        moduleName: 'surveys',
        viewName: 'surveys-list',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'surveys/assign',
        title: 'Asignaciones',
        moduleName: 'surveys',
        viewName: 'surveys-assign',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Verificaciones',
    moduleName: 'verifications',
    viewName: 'verifications',
    icon: 'list_alt',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'verifications/ended',
        title: 'En ejecución',
        moduleName: 'verifications',
        viewName: 'verification-ended',
        icon: '',
        class: 'grading',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'verifications/supervisor',
        title: 'Superv/SH/Proc/Carn/Merca/Geren',
        moduleName: 'verifications-supervisor',
        viewName: 'verifications-supervisor',
        icon: '',
        class: 'grading',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: 'incidences',
    title: 'Incidencias',
    moduleName: 'incidences',
    viewName: 'incidences',
    icon: 'rule',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '',
    title: 'Reportes',
    moduleName: 'reports',
    viewName: 'reports',
    icon: 'poll',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'reports/supervisor/general',
        title: 'General',
        moduleName: 'reports',
        viewName: 'reports-supervisor-general',
        icon: '',
        class: 'grading',
        groupTitle: false,
        submenu: []
      }
    ]
  }
];

const processes: RouteInfo[] = [
  {
    path: 'questions',
    title: 'Preguntas',
    moduleName: 'questions',
    viewName: 'question',
    icon: 'help_center',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '',
    title: 'Plantillas',
    moduleName: 'surveys',
    viewName: 'surveys',
    icon: 'list_alt',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'surveys/list',
        title: 'Listado',
        moduleName: 'surveys',
        viewName: 'surveys-list',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'surveys/assign',
        title: 'Asignaciones',
        moduleName: 'surveys',
        viewName: 'surveys-assign',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Verificaciones',
    moduleName: 'verifications',
    viewName: 'verifications',
    icon: 'list_alt',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'verifications/ended',
        title: 'En ejecución',
        moduleName: 'verifications',
        viewName: 'verification-ended',
        icon: '',
        class: 'grading',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'verifications/supervisor',
        title: 'Superv/SH/Proc/Carn/Merca/Geren',
        moduleName: 'verifications-supervisor',
        viewName: 'verifications-supervisor',
        icon: '',
        class: 'grading',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: 'incidences',
    title: 'Incidencias',
    moduleName: 'incidences',
    viewName: 'incidences',
    icon: 'rule',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '',
    title: 'Reportes',
    moduleName: 'reports',
    viewName: 'reports',
    icon: 'poll',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'reports/supervisor/general',
        title: 'General',
        moduleName: 'reports',
        viewName: 'reports-supervisor-general',
        icon: '',
        class: 'grading',
        groupTitle: false,
        submenu: []
      }
    ]
  }
];

const butcher: RouteInfo[] = [
  {
    path: 'questions',
    title: 'Preguntas',
    moduleName: 'questions',
    viewName: 'question',
    icon: 'help_center',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '',
    title: 'Plantillas',
    moduleName: 'surveys',
    viewName: 'surveys',
    icon: 'list_alt',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'surveys/list',
        title: 'Listado',
        moduleName: 'surveys',
        viewName: 'surveys-list',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'surveys/assign',
        title: 'Asignaciones',
        moduleName: 'surveys',
        viewName: 'surveys-assign',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Verificaciones',
    moduleName: 'verifications',
    viewName: 'verifications',
    icon: 'list_alt',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'verifications/ended',
        title: 'En ejecución',
        moduleName: 'verifications',
        viewName: 'verification-ended',
        icon: '',
        class: 'grading',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'verifications/supervisor',
        title: 'Superv/SH/Proc/Carn/Merca/Geren',
        moduleName: 'verifications-supervisor',
        viewName: 'verifications-supervisor',
        icon: '',
        class: 'grading',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: 'incidences',
    title: 'Incidencias',
    moduleName: 'incidences',
    viewName: 'incidences',
    icon: 'rule',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '',
    title: 'Reportes',
    moduleName: 'reports',
    viewName: 'reports',
    icon: 'poll',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'reports/supervisor/general',
        title: 'General',
        moduleName: 'reports',
        viewName: 'reports-supervisor-general',
        icon: '',
        class: 'grading',
        groupTitle: false,
        submenu: []
      }
    ]
  }
];

const safetyHygiene: RouteInfo[] = [
  {
    path: 'questions',
    title: 'Preguntas',
    moduleName: 'questions',
    viewName: 'question',
    icon: 'help_center',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '',
    title: 'Plantillas',
    moduleName: 'surveys',
    viewName: 'surveys',
    icon: 'list_alt',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'surveys/list',
        title: 'Listado',
        moduleName: 'surveys',
        viewName: 'surveys-list',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'surveys/assign',
        title: 'Asignaciones',
        moduleName: 'surveys',
        viewName: 'surveys-assign',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Verificaciones',
    moduleName: 'verifications',
    viewName: 'verifications',
    icon: 'list_alt',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'verifications/ended',
        title: 'En ejecución',
        moduleName: 'verifications',
        viewName: 'verification-ended',
        icon: '',
        class: 'grading',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'verifications/supervisor',
        title: 'Superv/SH/Proc/Carn/Merca/Geren',
        moduleName: 'verifications-supervisor',
        viewName: 'verifications-supervisor',
        icon: '',
        class: 'grading',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: 'incidences',
    title: 'Incidencias',
    moduleName: 'incidences',
    viewName: 'incidences',
    icon: 'rule',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '',
    title: 'Reportes',
    moduleName: 'reports',
    viewName: 'reports',
    icon: 'poll',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'reports/supervisor/general',
        title: 'General',
        moduleName: 'reports',
        viewName: 'reports-supervisor-general',
        icon: '',
        class: 'grading',
        groupTitle: false,
        submenu: []
      }
    ]
  }
];

const manager: RouteInfo[] = [
  {
    path: 'incidences',
    title: 'Incidencias',
    moduleName: 'incidences',
    viewName: 'incidences',
    icon: 'rule',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '',
    title: 'Reportes',
    moduleName: 'reports',
    viewName: 'reports',
    icon: 'poll',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: '/',
        title: 'Superv/SH/Proc/Carn/Merca/Geren',
        moduleName: 'supervisor',
        viewName: 'supervisor',
        icon: '',
        class: 'ml-sub-menu',
        groupTitle: false,
        submenu: [
          {
            path: 'reports/supervisor/general',
            title: 'General',
            moduleName: 'supervisor',
            viewName: 'reports-supervisor-general',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'reports/supervisor/monthly',
            title: 'Mensual',
            moduleName: 'supervisor',
            viewName: 'reports-supervisor-monthly',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          }
        ]
      },
      {
        path: '/',
        title: 'Auditor',
        moduleName: 'auditor',
        viewName: 'auditor',
        icon: '',
        class: 'ml-sub-menu',
        groupTitle: false,
        submenu: [
          {
            path: 'reports/auditor/general',
            title: 'General',
            moduleName: 'auditor',
            viewName: 'reports-auditor-general',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          }
        ]
      }
    ]
  }
];

const departmentManager: RouteInfo[] = [
  {
    path: 'incidences',
    title: 'Incidencias',
    moduleName: 'incidences',
    viewName: 'incidences',
    icon: 'rule',
    class: '',
    groupTitle: false,
    submenu: []
  }
];

const branchManager: RouteInfo[] = [
  {
    path: '',
    title: 'Verificaciones',
    moduleName: 'verifications',
    viewName: 'verifications',
    icon: 'list_alt',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'verifications/completed',
        title: 'Terminadas',
        moduleName: 'verifications',
        viewName: 'verifications-completed',
        icon: '',
        class: 'grading',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: 'incidences',
    title: 'Incidencias',
    moduleName: 'incidences',
    viewName: 'incidences',
    icon: 'rule',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '',
    title: 'Reportes',
    moduleName: 'reports',
    viewName: 'reports',
    icon: 'poll',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'reports/supervisor/general',
        title: 'Superv/SH/Proc/Carn/Merca/Geren',
        moduleName: 'reports',
        viewName: 'reports-supervisor-general',
        icon: '',
        class: 'grading',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'reports/auditor/general',
        title: 'Auditor',
        moduleName: 'reports',
        viewName: 'reports-auditor-general',
        icon: '',
        class: 'grading',
        groupTitle: false,
        submenu: []
      }
    ]
  }
];

const supervisor: RouteInfo[] = [
  {
    path: 'reports/supervisor/general',
    title: 'Reporte',
    moduleName: 'reports',
    viewName: 'reports',
    icon: 'poll',
    class: '',
    groupTitle: false,
    submenu: []
  }
];

const auditor: RouteInfo[] = [
  {
    path: 'reports/auditor/general',
    title: 'Reporte',
    moduleName: 'reports',
    viewName: 'reports',
    icon: 'poll',
    class: '',
    groupTitle: false,
    submenu: []
  }
];

const adminSuggestions: RouteInfo[] = [
  {
    path: '',
    title: 'Quejas y sugerencias',
    moduleName: 'suggestions',
    viewName: 'suggestions',
    icon: 'report_problem',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: '/suggestions/register',
        title: 'Registro',
        moduleName: 'suggestions-register',
        viewName: 'suggestions-register',
        icon: '',
        class: 'ml-sub-sub-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/suggestions/reports',
        title: 'Reporte',
        moduleName: 'suggestions-report',
        viewName: 'suggestions-report',
        icon: '',
        class: 'ml-sub-sub-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  }
];

export const ROUTES: InRoutes[] = [
  {
    // supervisor
    role_id: 1,
    routes: supervisor,
    component_routes: supervisorRoutes
  },
  {
    // auditor
    role_id: 2,
    routes: auditor,
    component_routes: auditorRoutes
  },
  {
    // supervisor admin
    role_id: 3,
    routes: supervisorAdmin,
    component_routes: adminSupervisorRoutes
  },
  {
    // auditor admin
    role_id: 4,
    routes: auditorAdmin,
    component_routes: adminAuditorRoutes
  },
  {
    // department manager
    role_id: 5,
    routes: departmentManager,
    component_routes: departmentManagerRoutes
  },
  {
    // branch manager
    role_id: 6,
    routes: branchManager,
    component_routes: bManagerRoutes
  },
  {
    // manager
    role_id: 7,
    routes: manager,
    component_routes: managerRoutes
  },
  {
    // admin
    role_id: 8,
    routes: admin,
    component_routes: adminRoutes
  },
  {
    // cm
    role_id: 0,
    routes: cm,
    component_routes: adminRoutes
  },
  {
    // safety hygiene
    role_id: 9,
    routes: supervisor,
    component_routes: supervisorRoutes
  },
  {
    // processes
    role_id: 10,
    routes: supervisor,
    component_routes: supervisorRoutes
  },
  {
    // admin suggestions
    role_id: 11,
    routes: adminSuggestions,
    component_routes: adminSuggestionsRoutes
  },
  {
    // butcher
    role_id: 12,
    routes: supervisor,
    component_routes: supervisorRoutes
  }
];
