import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/core/service/data.service';
import { InFilter } from 'src/app/interfaces/filter.interface';
import { Branch } from 'src/app/models/branch';
import { Department } from 'src/app/models/department';

@Component({
  selector: 'app-branch-department-filter',
  templateUrl: './branch-department-filter.component.html',
  styleUrls: ['./branch-department-filter.component.sass']
})
export class BranchDepartmentFilterComponent implements OnInit, OnDestroy {
  branches: Branch[] = [];
  branchesControl = new FormControl(0);
  branchesSubscription: Subscription;

  departments: Department[] = [];
  departmentsControl = new FormControl(0);
  departmetsSubscription: Subscription;

  filter: InFilter;
  filterSubscription: Subscription;

  @Output() changeBranch = new EventEmitter<number>();
  @Output() changeDepartment = new EventEmitter<number>();

  constructor(
    private dataService: DataService
  ) {
    this.branchesSubscription = this.dataService.branches.subscribe((data) => {
      this.branches = data;
    });

    this.departmetsSubscription = this.dataService.departments.subscribe((data) => {
      this.departments = data;
      this.departments.sort((a, b) => (a.name > b.name) ? 1 : -1);
    });

    this.filter = this.dataService.filterValue;
    this.filterSubscription = this.dataService.filter.subscribe((filter) => {
      this.filter = filter;
      this.dataService.filterData();
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.branchesSubscription.unsubscribe();
    this.filterSubscription.unsubscribe();
  }

  branchSelect(event: any): void {
    const b: Branch = event.value;
    this.changeBranch.emit(b.id);
    this.filter.branch_id = b.id ? b.id : 0;
    this.dataService.setFilter(this.filter);
  }

  departmentSelect(event: any): void {
    this.changeDepartment.emit(event.value);
    this.filter.department_id = event.value;
    this.dataService.setFilter(this.filter);
  }

}
