import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { InNavigation } from 'src/app/core/models/navigation.interface';
import { DataService } from 'src/app/core/service/data.service';
import { InDateFilter } from 'src/app/interfaces/filter.interface';

@Component({
  selector: 'app-section-layout',
  templateUrl: './section-layout.component.html',
  styleUrls: ['./section-layout.component.sass']
})
export class SectionLayoutComponent implements OnInit, OnDestroy {
  navigationSubscription: Subscription;
  navigation: InNavigation;

  dateFilterSubscription: Subscription;
  dateFilter: InDateFilter;

  constructor(
    private dataService: DataService
  ) {
    this.navigationSubscription = this.dataService.navigation.subscribe((data) => {
      this.navigation = data;
    });

    this.dateFilterSubscription = this.dataService.dateFilter.subscribe((data) => {
      this.dateFilter = data;
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
    this.dateFilterSubscription.unsubscribe();
  }

}
