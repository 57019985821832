import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { InFieldTypes } from 'src/app/interfaces/field.interface';
import { InDateFilter, InFilter } from 'src/app/interfaces/filter.interface';
import { InStatusTypes } from 'src/app/interfaces/status-types';
import { InUserTypes } from 'src/app/interfaces/user-types.interface';
import { Branch } from 'src/app/models/branch';
import { Department } from 'src/app/models/department';
import { GlobalService } from 'src/app/services/global/global.service';
import { InNavigation } from '../models/navigation.interface';
import { InAccessToken } from '../models/token.interface';
import { InUser } from '../models/user.interface';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  // Roles registered (spanish names - english names)
  // 1- Supervisor (web access restricted) - access added on 01-19-2022
  // 2- Auditor (web access restricted) - access added on 01-19-2022
  // 3- Administrador de supervisores - Super admin *
  // 4- Administrador de auditores - Auditor admin (no incidences) *
  // 5- Jefe de departamento - Department manager (department incidences) *
  // 6- Gerente de tienda- Store manager (All reports and incidences) *
  // 7- Director general - General manager (All reports) *
  // 8- Administrador - Admin *************
  // 9- Seguridad e Higiene
  // 10- Procesos


  // Role routes for services
  private userRoutes = [
    {
      id: 1,
      route: '/supervisor',
      reports: '/reports',
      resources: '/resources'
    },
    {
      id: 2,
      route: '/auditor',
      reports: '/reports',
      resources: '/resources'
    },
    {
      id: 3,
      route: '/admin-supervisor',
      def_questions: '/default-questions',
      def_surveys: '/default-surveys',
      def_sbd: '/default-survey-branch-departments',
      catalogue_type: '/catalogue-types',
      surveys: '/surveys',
      copy_surveys: '/copy-surveys',
      incidences: '/incidences',
      reports: '/reports',
      resources: '/resources'
    },
    {
      id: 4,
      route: '/admin-auditor',
      catalogue: '/catalogue',
      def_questions: '/default-questions',
      def_surveys: '/default-surveys',
      def_sbd: '/default-survey-branch-departments',
      catalogue_type: '/catalogue-types',
      surveys: '/surveys',
      copy_surveys: '/copy-surveys',
      reports: '/reports',
      resources: '/resources'
    },
    {
      id: 5,
      route: '/department-head',
      incidences: '/incidences'
    },
    {
      id: 6,
      route: '/store-manager',
      incidences: '/incidences',
      reports: '/reports',
      resources: '/resources'
    },
    {
      id: 7,
      route: '/general-manager',
      incidences: '/incidences',
      reports: '/reports',
      resources: '/resources'
    },
    {
      id: 8,
      route: '/superadmin',
      catalogue: '/catalogues',
      def_questions: '/default-questions',
      def_surveys: '/default-surveys',
      def_sbd: '/default-survey-branch-departments',
      catalogue_type: '/catalogue-type',
      surveys: '/surveys',
      copy_surveys: '/copy-surveys',
      incidences: '/incidences',
      reports: '/reports',
      resources: '/resources',
      contact_source: '/get-contact-sources',
      status_suggestion: '/get-statuses',
      comment_suggestions: '/comment-suggestions',
      get_comment_suggestions: '/get-comment-suggestions'
    },
    {
      id: 0,
      route: '/cm',
      catalogue: '/catalogues',
      def_questions: '/default-questions',
      def_surveys: '/default-surveys',
      def_sbd: '/default-survey-branch-departments',
      catalogue_type: '/catalogue-type',
      surveys: '/surveys',
      copy_surveys: '/copy-surveys',
      incidences: '/incidences',
      reports: '/reports',
      resources: '/resources'
    },
    {
      id: 9,
      route: '/cm',
      def_questions: '/default-questions',
      def_surveys: '/default-surveys',
      def_sbd: '/default-survey-branch-departments',
      catalogue_type: '/catalogue-types',
      surveys: '/surveys',
      copy_surveys: '/copy-surveys',
      incidences: '/incidences',
      reports: '/reports',
      resources: '/resources'
    },
    {
      id: 10,
      route: '/cm',
      def_questions: '/default-questions',
      def_surveys: '/default-surveys',
      def_sbd: '/default-survey-branch-departments',
      catalogue_type: '/catalogue-types',
      surveys: '/surveys',
      copy_surveys: '/copy-surveys',
      incidences: '/incidences',
      reports: '/reports',
      resources: '/resources'
    },
    {
      id: 11,
      route: '/superadmin',
      contact_source: '/get-contact-sources',
      status_suggestion: '/get-statuses',
      comment_suggestions: '/comment-suggestions',
      get_comment_suggestions: '/get-comment-suggestions'
    },
    {
      id: 12,
      route: '/cm',
      def_questions: '/default-questions',
      def_surveys: '/default-surveys',
      def_sbd: '/default-survey-branch-departments',
      catalogue_type: '/catalogue-types',
      surveys: '/surveys',
      copy_surveys: '/copy-surveys',
      incidences: '/incidences',
      reports: '/reports',
      resources: '/resources'
    },
  ];

  public routes: any = {};

  // User Data
  private userSubject: BehaviorSubject<InUser>;
  public user: Observable<InUser>;

  private tokenSubject: BehaviorSubject<InAccessToken>;
  public token: Observable<InAccessToken>;

  private navigationSubject: BehaviorSubject<InNavigation>;
  public navigation: Observable<InNavigation>;

  // Branches departments data
  private branchesSubject: BehaviorSubject<Branch[]>;
  public branches: Observable<Branch[]>;

  // Departments data
  private departmentsSubject: BehaviorSubject<Department[]>;
  public departments: Observable<Department[]>;

  ///////////////////////// Filtering data ///////////////////////////////
  // Filter
  private filterSubject: BehaviorSubject<InFilter> = new BehaviorSubject<InFilter>({
    branch_id: 0,
    department_id: 0,
    type: 0,
    status_type: 10
  } as InFilter);
  public filter: Observable<InFilter> = this.filterSubject.asObservable();
  // Date
  private dateFilterSubject: BehaviorSubject<InDateFilter> = new BehaviorSubject<InDateFilter>({
    start: null,
    end: null
  } as InDateFilter);
  public dateFilter: Observable<InDateFilter> = this.dateFilterSubject.asObservable();

  // Global dataset
  private datasetSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public dataset: Observable<any[]> = this.datasetSubject.asObservable();

  // Filtered data
  private filteredDataSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public filteredData: Observable<any[]> = this.filteredDataSubject.asObservable();
  ///////////////////////// End filtering data ///////////////////////////////

  // Data sets
  public userTypes: InUserTypes[] = [
    { id: 7, name: 'Gerencia' },
    { id: 6, name: 'Mercadotecnia' },
    { id: 5, name: 'Carniceria' },
    { id: 4, name: 'Procesos' },
    { id: 3, name: 'Seguridad e higiene' },
    { id: 2, name: 'Auditor' },
    { id: 1, name: 'Supervisor' }
  ];

  public mapOfUserTypes = {
    7: 'Gerencia',
    6: 'Mercadotecnia',
    5: 'Carniceria',
    4: 'Procesos',
    3: 'Seguridad e higiene',
    2: 'Auditor',
    1: 'Supervisor'
  };

  public statusTypes: InStatusTypes[] = [
    { id: 1, name: 'Iniciada' },
    { id: 3, name: 'Cancelada' },
    { id: 2, name: 'En proceso' },
    { id: 0, name: 'Terminada' }
  ];

  public fieldTypes: InFieldTypes[] = [
    { id: 1, name: 'Sucursal', active: true, key: 'branch_id' },
    { id: 2, name: 'Departamento', active: true, key: 'department_id' },
    { id: 3, name: 'Incidencia', active: true, key: 'incidences' },
    //{id: 4, name: 'Auditor', active: true, key: 'auditor_id'}
    { id: 5, name: 'Minutos', active: true, key: 'minutes' },
  ];

  constructor(
    private globalService: GlobalService,
    private datepipe: DatePipe
  ) {
    this.setData();
  }

  private setData(): void { // Main function to set data
    // User Data
    this.userSubject = new BehaviorSubject<InUser>(
      JSON.parse(sessionStorage.getItem('user'))
    );
    this.user = this.userSubject.asObservable();


    // Token
    this.tokenSubject = new BehaviorSubject<InAccessToken>(
      JSON.parse(sessionStorage.getItem('token'))
    );
    this.token = this.tokenSubject.asObservable();


    // Navigation
    this.navigationSubject = new BehaviorSubject<InNavigation>(
      JSON.parse(sessionStorage.getItem('navigation'))
    );
    this.navigation = this.navigationSubject.asObservable();


    // Branches - Departments
    this.branchesSubject = new BehaviorSubject<Branch[]>(
      JSON.parse(sessionStorage.getItem('branches'))
    );
    this.branches = this.branchesSubject.asObservable();

    // Departments
    this.departmentsSubject = new BehaviorSubject<Department[]>(
      JSON.parse(sessionStorage.getItem('departments'))
    );
    this.departments = this.departmentsSubject.asObservable();
  }


  // Clear all stored data
  public clear(): void {
    localStorage.clear();
    sessionStorage.clear();
    this.setData();
  }

  // Methods to set and get observable data //
  // User
  public get userValue(): InUser {
    return this.userSubject.value;
  }
  public setUserValue(user: InUser): void {
    sessionStorage.setItem('user', JSON.stringify(user));
    this.userSubject.next(user);
  }

  // Token
  public get tokenValue(): InAccessToken {
    return this.tokenSubject.value;
  }
  public setTokenValue(token: InAccessToken): void {
    sessionStorage.setItem('token', JSON.stringify(token));
    this.tokenSubject.next(token);
  }

  // Navigation
  public get navigationValue(): InNavigation {
    return this.navigationSubject.value;
  }
  public setNavigationValue(data: InNavigation): void {
    sessionStorage.setItem('navigation', JSON.stringify(data));
    this.navigationSubject.next(data);
  }

  // Branches - Departments
  public get branchesValue(): Branch[] {
    return this.branchesSubject.value;
  }
  public setBranchesValue(data: Branch[]): void {
    sessionStorage.setItem('branches', JSON.stringify(data));
    this.branchesSubject.next(data);
  }

  // Departments
  public get departmentsValue(): Department[] {
    return this.departmentsSubject.value;
  }
  public setDepartmentsValue(data: Department[]): void {
    sessionStorage.setItem('departments', JSON.stringify(data));
    this.departmentsSubject.next(data);
  }

  ///////////////////////// Filtering data ///////////////////////////////
  // Global dataset(type, branch, department)
  public get filterValue(): InFilter {
    return this.filterSubject.value;
  }
  public setFilter(data?: InFilter): void {
    if (!data) {
      data = { branch_id: 0, department_id: 0, type: 0, status_type: 10 } as InFilter;
    }
    this.filterSubject.next(data);
  }
  public get dateFilterValue(): InDateFilter {
    return this.dateFilterSubject.value;
  }
  public setDateFilter(data?: InDateFilter): void {
    if (!data) {
      const currentDate = new Date();
      const minDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7);
      const maxDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

      data = {
        start: this.datepipe.transform(minDate, 'yyyy-MM-dd'),
        start_complete: minDate.toISOString(),
        end: this.datepipe.transform(maxDate, 'yyyy-MM-dd'),
        end_complete: maxDate.toISOString()
      } as InDateFilter;
    }
    this.dateFilterSubject.next(data);
  }

  // Global dataset(type, branch, department)
  public get datasetValue(): any[] {
    return this.datasetSubject.value;
  }
  public setDataset(data: any[]): void {
    this.datasetSubject.next(data);
  }

  // Global filtered data (type, branch, department)
  public get filteredDataValue(): any[] {
    return this.filteredDataSubject.value;
  }
  public setFilteredData(data: any[]): void {
    this.filteredDataSubject.next(data);
  }

  public filterData(): void {
    const filter = this.filterValue;
    let data = this.datasetValue;

    if (filter.type !== 0) {
      data = data.filter(x => x.type === filter.type);
    }
    if (filter.branch_id !== 0) {
      data = data.filter(x => x.branchDepartment ? (x.branchDepartment.branch_id === filter.branch_id) : true);
    }
    if (filter.department_id !== 0) {
      data = data.filter(x => x.branchDepartment ? (x.branchDepartment.department_id === filter.department_id) : true);
    }
    if (filter.status_type !== 10) {
      // if status_type equals 3 means that user wants to filter cancelled data
      data = data.filter(x => filter.status_type === 3 ? x.cancelled : (x.status === filter.status_type && !x.cancelled));
    }
    this.setFilteredData(data);
  }
  ///////////////////////// End filtering data ///////////////////////////////

  ///////////////////////// Get route for services ///////////////////////////
  getServicesRoutes(): any {
    console.log(this.userValue)
    if (this.userValue) {
      if (this.userValue.role_id > 8 && (this.userValue.role_id !== 9 && this.userValue.role_id !== 10 && this.userValue.role_id !== 11 && this.userValue.role_id !== 12)) {
        return this.routes = this.userRoutes.find(x => x.id === 0);
      }
      else {
        return this.routes = this.userRoutes.find(x => x.id === this.userValue.role_id);
      }
    } else {
      return this.routes = [];
    }
  }
  getServicesPrefix(): any {
    if (this.userValue) {
      if (this.userValue.role_id > 8 && (this.userValue.role_id !== 9 && this.userValue.role_id !== 10 && this.userValue.role_id !== 11 && this.userValue.role_id !== 12)) {
        return "cm"
      }
      else
        return "superadmin"

    } else {
      return "auth";
    }
  }
  ///////////////////////// Get route for services ///////////////////////////


  // Get data from api services
  // branches - departments
  getBranchesData(): void {
    this.globalService.getBranches().subscribe(
      res => {
        this.setBranchesValue(res.data);
      },
      error => {
        this.setBranchesValue([]);
      }
    );
  }

  // branches with incidences - departments
  getBranchesIncidencesData(): void {
    this.globalService.getBranchesIncidences().subscribe(
      res => {
        this.setBranchesValue(res.data);
      },
      error => {
        this.setBranchesValue([]);
      }
    );
  }

  // departments
  getDepartmentsData(active = null): void {
    this.globalService.getDepartments(active).subscribe(
      res => {
        this.setDepartmentsValue(res.data);
      },
      error => {
        this.setDepartmentsValue([]);
      }
    );
  }
}
