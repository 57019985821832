import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthLayoutComponent } from './app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './app-layout/main-layout/main-layout.component';
import { HeaderComponent } from './header/header.component';
import { PageLoaderComponent } from './page-loader/page-loader.component';
import { RightSidebarComponent } from './right-sidebar/right-sidebar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SectionLayoutComponent } from './app-layout/section-layout/section-layout.component';
import { BlankLayoutComponent } from './app-layout/blank-layout/blank-layout.component';

const components = [
  HeaderComponent,
  PageLoaderComponent,
  SidebarComponent,
  RightSidebarComponent,
  AuthLayoutComponent,
  MainLayoutComponent,
  SectionLayoutComponent,
  BlankLayoutComponent
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [components],
  exports: [components]
})
export class LayoutModule {}
