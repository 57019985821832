import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/core/service/data.service';

@Component({
  selector: 'app-blank-layout',
  templateUrl: './blank-layout.component.html',
  styleUrls: ['./blank-layout.component.sass']
})
export class BlankLayoutComponent implements OnInit {

  constructor(
    private dataService: DataService
  ) {
    this.dataService.setNavigationValue({title: 'Página de prueba', subtitle: 'subtítulo'});
  }

  ngOnInit(): void {
  }

}
