<h3 mat-dialog-title>{{dialogTitle}}</h3>
<mat-dialog-content>
    <mat-form-field class="header-select default-full-width" appearance="outline">
        <mat-label>Fecha</mat-label>
        <mat-date-range-input [rangePicker]="rangePicker" [formGroup]="rangeForm">
          <input matStartDate formControlName="start" placeholder="Fecha inicio" placeholder="DD/MM/YYYY">
          <input matEndDate formControlName="end" placeholder="Fecha fin" placeholder="DD/MM/YYYY">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
        <mat-date-range-picker #rangePicker disabled="false">
          <!-- <mat-date-range-picker-actions>
            <button mat-button matDateRangePickerCancel>Cancelar</button>
            <button mat-raised-button color="primary" matDateRangePickerApply>Aceptar</button>
          </mat-date-range-picker-actions> -->
        </mat-date-range-picker>
        <mat-error *ngFor="let validation of validationMessages.date">
          <span *ngIf="formService.field(rangeForm, validation.type, 'start') || formService.field(rangeForm, validation.type, 'end')">
              {{ validation.message }}
          </span>
      </mat-error>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-button color="primary" cdkFocusInitial (click)="save()">Aceptar</button>
</mat-dialog-actions>