export const routes: {path: string}[] = [
    {
        path: '/home'
    },
    {
        path: '/questions'
    },
    {
        path: '/surveys/list'
    },
    {
        path: '/surveys/assign'
    },
    {
        path: '/verifications'
    },
    {
        path: '/verifications/supervisor'
    },
    {
        path: '/incidences'
    },
    {
        path: '/reports/supervisor/general'
    }
];
