<mat-form-field class="col header-select" appearance="outline">
    <mat-label>Tipo</mat-label>
    <mat-select  [formControl]="statusTypesControl" (selectionChange)="statusTypeSelect($event)">
        <mat-option [value]="10">
          Todos
        </mat-option>
        <mat-option *ngFor="let type of statusTypes" [value]="type.id">
          {{type.name}}
        </mat-option>
    </mat-select>
</mat-form-field>