import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { DepartmentFilterComponent } from './department-filter/department-filter.component';
import { TypeFilterComponent } from './type-filter/type-filter.component';
import { BranchFilterComponent } from './branch-filter/branch-filter.component';
import { BranchDepartmentFilterComponent } from './branch-department-filter/branch-department-filter.component';
import { DaterangePickerFilterComponent } from './daterange-picker-filter/daterange-picker-filter.component';
import { DaterangeDialogFilterComponent } from './daterange-dialog-filter/daterange-dialog-filter/daterange-dialog-filter.component';
import { StatusFilterComponent } from './status-filter/status-filter.component';
import { FieldFilterComponent } from './field-filter/field-filter.component';

const components = [
  TypeFilterComponent,
  BranchFilterComponent,
  DepartmentFilterComponent,
  BranchDepartmentFilterComponent,
  DaterangePickerFilterComponent,
  DaterangeDialogFilterComponent,
  StatusFilterComponent,
  FieldFilterComponent
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [components],
  exports: [components]
})
export class FiltersModule { }
