import { Injectable } from '@angular/core';
import Swal, { SweetAlertCustomClass } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  public customStyle: SweetAlertCustomClass = {
    container: sessionStorage.getItem('menuOption') === 'menu_dark' ? 'dark-primary' : 'alert-primary',
    title: 'alert-title',
    icon: 'alert-icon',
    confirmButton: 'alert-confirm-btn',
    cancelButton: 'alert-cancel-btn',
    input: 'alert-input',
  };

  constructor() { }

  // Default dialogs
  successDialog(title?: string, text?: string) {
    return Swal.fire({
        position: 'center',
        icon: 'success',
        text,
        title: title ? title : 'Acción completada',
        showConfirmButton: false,
        timer: 1500
    });
  }

  warningMessage(title?: string, text?: string) {
    return Swal.fire({
        position: 'center',
        icon: 'warning',
        text,
        title,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#2AAA0B',
        showCloseButton: true,
    });
  }

  errorMessage(title?: string, text?: string) {
    return Swal.fire({
        position: 'center',
        icon: 'error',
        html: text ? text : "",
        // text,
        title: title ? title : 'Ocurrió un error',
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#2AAA0B',
        showCloseButton: true,
    });
  }

  // Default confirm dialogs
  confirmDialog(title?: string, text?: string, cancel?: string, confirm?: string) {
    return Swal.fire({
        position: 'center',
        icon: 'warning',
        title: title ? title : 'Confirme la operación',
        text,
        showCancelButton: true,
        cancelButtonText: cancel ? cancel : 'Cancelar',
        showConfirmButton: true,
        confirmButtonText: confirm ? confirm : 'Aceptar',
        showCloseButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: this.customStyle
    });
  }

  infoDialog(title?: string, text?: string) {
    return Swal.fire({
      icon: 'info',
      position: 'center',
      title,
      text,
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: 'Cerrar',
      showCloseButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: this.customStyle
    });
  }
}
