<mat-form-field class="col header-select" appearance="outline">
    <mat-label>Departamento</mat-label>
    <mat-select  [formControl]="departmentControl" (selectionChange)="departmentSelect($event)">
        <mat-option [value]="0">
           Todos
        </mat-option>
        <mat-option *ngFor="let department of departments" [value]="department.id">
          {{department.name}}
        </mat-option>
    </mat-select>
</mat-form-field>