import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import localeEsMx from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';

import { MaterialModule } from './material.module';
import { ClickOutsideModule } from 'ng-click-outside';

import { ChartsModule as chartjsModule } from 'ng2-charts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxEchartsModule } from 'ngx-echarts';
import { GaugeModule } from 'angular-gauge';
import { AgmCoreModule } from '@agm/core';

import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PrimengModule } from './primeng.module';
import { environment } from 'src/environments/environment';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

const sharedModules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  MaterialModule,
  PrimengModule,
  NgbModule,
  NgxSpinnerModule,
  PerfectScrollbarModule,
  ClickOutsideModule,
  chartjsModule,
  NgApexchartsModule,
  NgxEchartsModule.forRoot({
    echarts: () => import('echarts')
  }),
  GaugeModule.forRoot(),
  AgmCoreModule.forRoot({
    apiKey: environment.googleMaps.apiKey,
    libraries: ['places']
  }),
];

registerLocaleData(localeEsMx, 'es');

@NgModule({
  declarations: [],
  imports: [sharedModules],
  exports: [sharedModules],
  providers: [
    { provide: LOCALE_ID, useValue: 'es' },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    DatePipe
  ]
})
export class SharedModule {}
