export const routes: { path: string }[] = [
    {
        path: '/home'
    },
    {
        path: '/suggestions/register',
    },
    {
        path: '/suggestions/reports'
    }
];